import { Container, Carousel } from "react-bootstrap";
import { Reference } from "./Reference";
import { references } from "./references";
import styles from "./reference.module.css";
import { forwardRef, useState } from "react";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";

// consider using left right arrangement instead of carousel
export const ReferenceSection = forwardRef<HTMLElement>((props, ref) => {
  const windowDimensions = useWindowDimensions();
  const [activeIndex, setActiveIndex] = useState<number>(1);
  const onSelect = (evtKey: number) => {
    setActiveIndex(evtKey);
  };
  return (
    <section className={`${styles.referenceSection} py-5`} ref={ref}>
      <Container className="h-100 d-flex">
        <Carousel
          variant="dark"
          interval={null}
          activeIndex={activeIndex}
          onSelect={onSelect}
          defaultActiveIndex={1}
          className="m-auto"
          controls={windowDimensions.width > 769}
        >
          {references.map((reference, ind) => (
            <Carousel.Item key={ind}>
              <Reference reference={reference} />
            </Carousel.Item>
          ))}
        </Carousel>
      </Container>
    </section>
  );
});
