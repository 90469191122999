export const niceColors: { [key: string]: string[] } = {
  hex: [
    "#8ecae6",
    "#219ebc",
    "#457B9D",
    "#023047",
    "#ffb703",
    "#fb8500",
    "#D6CCC2",
    "#E63946",
    "#588157",
    "#A3B18A",
    "#D5BDAF"
  ],
  rgb: [
    "142, 202, 230",
    "33, 158, 188",
    "69, 123, 157",
    "2, 48, 71",
    "255, 183, 3",
    "251, 133, 0",
    "214, 204, 194",
    "230, 57, 70",
    "88, 129, 87",
    "163, 177, 138",
    "213, 189, 175"
  ]
};
