import { ComponentProps } from "react";
import { JobDescription } from "./JobDetails";
import { ExperienceType } from "./experiences";
import { Container, Row, Col } from "react-bootstrap";
import JobTitle from "./JobTitle";
import SkillsUsed from "./SkillsUsed";
interface IExperienceItemProps extends ComponentProps<any> {
  experience: ExperienceType;
  backgroundColor?: string;
}
function ExperienceItem({
  experience,
  backgroundColor,
  ...rest
}: IExperienceItemProps) {
  const { descriptions, skills } = experience;

  return (
    <div
      {...rest}
      style={{
        backgroundColor: backgroundColor
          ? `rgb(${backgroundColor},0.1)`
          : "unset"
      }}
    >
      <Container className="py-5 my-5 d-flex">
        <Row xs={1} lg={2} className="m-auto w-100">
          <Col className="text-start">
            <JobTitle experience={experience} />
            <SkillsUsed skills={skills} className="d-none d-lg-flex" />
          </Col>
          <Col>
            <JobDescription descriptions={descriptions} />
            <SkillsUsed skills={skills} className="d-flex d-lg-none mt-5" />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ExperienceItem;
