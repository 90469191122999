import { ExperienceType } from "./experiences";
import { Stack } from "react-bootstrap";
import styles from "./work-experience.module.css";
import LocationWithCountryFlag from "../LocationWithCountryFlag/LocationWithCountryFlag";

interface IJobTitleProps {
  experience: ExperienceType;
  shadowColor?: string;
}

function JobTitle({ experience }: IJobTitleProps) {
  const { company, companyLink, title, location, startTime, endTime } =
    experience;
  return (
    <Stack className="align-items-start  pb-5">
      <div>
        <span className={`${styles.title} font-shadow-into-light`}>
          {title}
        </span>
        {" @"}
        <a
          href={companyLink}
          className={`${styles.title} font-shadow-into-light`}
        >
          {company}
        </a>
      </div>
      <div className={styles.roleTime}>
        {startTime} - {endTime}
      </div>
      <LocationWithCountryFlag
        location={location}
        className={`${styles.roleLocation}`}
      />
    </Stack>
  );
}

export default JobTitle;
