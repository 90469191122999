import { EducationType } from "../../types";
export const educationHistory: EducationType[] = [
  {
    schoolName: "University of Waterloo",
    degrees: ["Bachelor of Mathematics"],
    majors: ["Honours Mathematical Finance & Statistics"],
    notes: "With Distinction",
    from: "Sep, 2014",
    to: "Jul, 2017"
  }
];
