import React, { useRef } from "react";
import "./home.css";
import {
  Education,
  Footer,
  ReferenceSection,
  Summary,
  WorkExperience
} from "../components";
import { HomeAnchor } from "../components/HomeAnchor/HomeAnchor";
import { CgProfile } from "react-icons/cg";
import { MdWorkOutline } from "react-icons/md";
import { GiGraduateCap } from "react-icons/gi";
import { BsChatDots } from "react-icons/bs";
import { scrollToView } from "../utils";

export const Home = () => {
  const aboutRef = useRef(null) as React.MutableRefObject<HTMLElement | null>;
  const referenceRef = useRef(
    null
  ) as React.MutableRefObject<HTMLElement | null>;
  const workRef = useRef(null) as React.MutableRefObject<HTMLElement | null>;
  const educationRef = useRef(
    null
  ) as React.MutableRefObject<HTMLElement | null>;

  const footerRef = useRef() as React.MutableRefObject<HTMLElement>;
  const anchorData = [
    {
      id: "home-anchor-about",
      tooltip: "About Me",
      icon: <CgProfile onClick={() => scrollToView(aboutRef)} />
    },
    // {
    //   id: "home-anchor-reference",
    //   tooltip: "Recommendations",
    //   icon: (
    //     <MdOutlineRecommend
    //       onClick={() => scrollToView(referenceRef, { block: "center" })}
    //       style={{ fontSize: "1.1em" }}
    //     />
    //   )
    // },
    {
      id: "home-anchor-work",
      tooltip: "Work Experience",
      icon: <MdWorkOutline onClick={() => scrollToView(workRef)} />
    },
    {
      id: "home-anchor-education",
      tooltip: "Education",
      icon: (
        <GiGraduateCap
          onClick={() => scrollToView(educationRef, { block: "center" })}
          style={{ fontSize: "1.1em" }}
        />
      )
    },
    {
      id: "home-anchor-footer",
      tooltip: "Reach Out",
      icon: (
        <BsChatDots
          onClick={() => scrollToView(footerRef, { block: "nearest" })}
          style={{ fontSize: "0.85em" }}
        />
      )
    }
  ];
  return (
    <div className="home-page">
      <Summary ref={aboutRef} />
      <ReferenceSection ref={referenceRef} />
      <WorkExperience ref={workRef} />
      <Education ref={educationRef} />
      <Footer ref={footerRef} />
      <HomeAnchor data={anchorData} />
    </div>
  );
};
