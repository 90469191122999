import { Project } from "../../types";
import { uuid } from "../../utils";

export interface ExperienceType {
  company: string;
  companyLink?: string;
  title: string;
  location: string;
  startTime: string;
  endTime: string;
  projects: Project[];
  skills: string[];
  descriptions: { id: string; val: string }[];
}

export const workExperiences: ExperienceType[] = [
  {
    company: "A Glimpse of Jane",
    title: "Full Stack Engineer",
    location: "Toronto, ON, CA",
    startTime: "05/2023",
    endTime: "06/2023",
    projects: [{ id: "a-glimpse-of-jane", name: "A Glimpse of Jane" }],
    skills: [
      "React",
      "React Bootstrap",
      "TypeScript",
      "User Interface Design",
      "AWS Route53",
      "Vercel"
    ],
    descriptions: [
      {
        id: uuid(),
        val: "Developed a personal website using React, React Bootstrap, and TypeScript"
      },
      {
        id: uuid(),
        val: "Deployed and hosted the website using Vercel"
      },
      {
        id: uuid(),
        val: "Managed domain name using AWS Route53"
      },
      {
        id: uuid(),
        val: "Acquired proficiency in app development and deployment, user interface design, and project management"
      }
    ]
  },
  {
    company: "Lithic",
    companyLink: "https://www.lithic.com/",
    title: "Software Engineer II",
    location: "Toronto, ON, CA",
    startTime: "01/2023",
    endTime: "03/2023",
    projects: [{ id: "lithic-extension", name: "browser extension" }],
    skills: ["Vue", "Pinia", "TypeScript", "Cypress", "Vitest", "Storybook"],
    descriptions: [
      {
        id: uuid(),
        val: "Developed a browser extension using Vue, Pinia and TypeScript"
      },
      {
        id: uuid(),
        val: "Set up and wrote end-to-end tests for a browser extension using Cypress"
      },
      {
        id: uuid(),
        val: "Wrote component tests using Vitest, and UI tests using Storybook"
      }
    ]
  },
  {
    company: "Fintelics",
    companyLink: "https://www.fintelics.com/",
    title: "Technical Lead",
    location: "Toronto, ON, CA",
    startTime: "07/2021",
    endTime: "01/2023",
    projects: [
      { id: "axia-axpay-mobile", name: "AXpay iOS and Android mobile apps" },
      { id: "axia-axpay-for-business-web", name: "AXpay for Business web app" }
    ],
    skills: [
      "Node.js",
      "AWS Lambda",
      "EventBridge",
      "CloudWatch",
      "React Native",
      "React",
      "Express"
    ],
    descriptions: [
      {
        id: uuid(),
        val: "Led a team of 6 developers in mobile, web, and backend application development"
      },
      {
        id: uuid(),
        val: "Actively participated in the hiring process and helped the team to grow from 4 to 18 members"
      },
      { id: uuid(), val: "Mentored 5 new hires to be product owners" },
      {
        id: uuid(),
        val: "Utilized VSCode's LiveShare to pair-program with team members"
      },
      {
        id: uuid(),
        val: "Hosted lunch-and-learns and presented to a broad audience"
      },
      {
        id: uuid(),
        val: "Processed blockchain data on Ethereum and Aion Network with Node.js scripts"
      }
    ]
  },
  {
    company: "Fintelics",
    companyLink: "https://www.fintelics.com/",
    title: "Full Stack Engineer",
    location: "Toronto, ON, CA",
    startTime: "04/2020",
    endTime: "07/2021",
    projects: [{ id: "axia-wallet-iframe", name: "Wallet iframe" }],
    skills: ["AWS CodePipeline", "S3", "CloudFront", "React", "Express"],
    descriptions: [
      {
        id: uuid(),
        val: "Led the development of 6 web applications using React"
      },
      {
        id: uuid(),
        val: "Wrote REST APIs and unit tests using Express and Mocha"
      },
      {
        id: uuid(),
        val: "Used DBeaver to write SQL queries for migrations and post-migration testings"
      },
      {
        id: uuid(),
        val: "Automated build, delivery and release of web apps using AWS CodePipeline, S3, CloudFront and GitHub Actions"
      },
      {
        id: uuid(),
        val: "Conducted ERC20 smart contract test on Truffle local network andGoerli testnet using Node.js and web3.js"
      },
      {
        id: uuid(),
        val: "Analyzed and optimized gas consumption for smart contract functions, reducing transaction cost by 76%"
      }
    ]
  },
  {
    company: "Fintelics",
    companyLink: "https://www.fintelics.com/",
    title: "Mobile Application Developer",
    location: "Toronto, ON, CA",
    startTime: "07/2019",
    endTime: "04/2020",
    projects: [
      {
        id: "axia-wallet-mobile",
        name: "AXIA Wallet iOS and Android mobile app"
      },
      { id: "axia-wallet-web", name: "AXIA Wallet web app" }
    ],
    skills: [
      "AWS Cognito",
      "React Native",
      "Android Studio",
      "XCode",
      "Google Cloud Platform",
      "Solidity"
    ],
    descriptions: [
      {
        id: uuid(),
        val: "Developed iOS and Android applications using React Native, Redux, Redux Thunk, and Redux Saga"
      },
      {
        id: uuid(),
        val: "Enabled third party login using AWS Cognito userpool and Amplify"
      },
      {
        id: uuid(),
        val: "Distributed apps using Android Studio, Google play console, XCode and Apple developer portal"
      },
      {
        id: uuid(),
        val: "Expedited mobile apps' build and delivery process using fastlane"
      },
      {
        id: uuid(),
        val: "Improved backend server security by conducting penetration test with Burp Suite"
      }
    ]
  }
];
