import { Stack } from "react-bootstrap";
import styles from "./work-experience.module.css";

export function JobDescription({
  descriptions
}: {
  descriptions: { id: string; val: string }[];
}) {
  return (
    <Stack gap={1} className={styles.rightCol}>
      {/* <div className="fw-bold  me-auto">Details</div> */}
      {descriptions.map((s) => (
        <div key={s.id} className={styles.descriptionText}>
          {s.val}
        </div>
      ))}
    </Stack>
  );
}
