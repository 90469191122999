import { workExperiences } from "./experiences";
import ExperienceItem from "./ExperienceItem";
import { forwardRef } from "react";

export const WorkExperience = forwardRef<HTMLElement>((props, ref) => {
  return (
    <section ref={ref}>
      {workExperiences.map((we, ind) => (
        <ExperienceItem
          key={`${we.company}-${we.title}`}
          experience={we}
          backgroundColor={ind % 2 === 0 ? undefined : "52, 78, 65"}
          className="py-5"
        />
      ))}
    </section>
  );
});
