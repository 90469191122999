import React from "react";
import UserSummary from "./UserSummary";
import SkillChart from "./SkillChart";
import { Container, Row, Col } from "react-bootstrap";
export const Summary = React.forwardRef<HTMLElement>((props, ref) => {
  return (
    <section className="my-5 py-5" ref={ref}>
      <Container>
        <Row xs={1} lg={2}>
          <Col className="mb-4 my-sm-auto">
            <UserSummary />
          </Col>
          <Col className="mt-5 mt-lg-0">
            <SkillChart />
          </Col>
        </Row>
      </Container>
    </section>
  );
});
