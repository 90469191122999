import Stack from "react-bootstrap/Stack";
import Container from "react-bootstrap/Container";
import { Reference as ReferenceType } from "../../types/Reference";
import styles from "./reference.module.css";
import { BiSolidQuoteLeft, BiSolidQuoteRight } from "react-icons/bi";
export const Reference = ({ reference }: { reference: ReferenceType }) => {
  return (
    <Container className="my-5 px-5 position-relateive">
      <BiSolidQuoteLeft className={styles.quotationMark} />
      <Stack gap={3} className={`px-2 ${styles.referenceContainer}`}>
        <div className={styles.referenceLetter}>
          {reference.referenceLetter}
        </div>
        <div className={styles.referrer}>{reference.referrerName}</div>
      </Stack>
      <BiSolidQuoteRight className={styles.quotationMark} />
    </Container>
  );
};
