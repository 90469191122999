import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { Home } from "../pages";
import logo from "../resources/images/logo.png";
import styles from "./layout.module.css";
import BackToTop from "./BackToTop/BackToTop";
const AppLayout = () => {
  return (
    <>
      <Navbar className="bg-body-tertiary">
        <Container>
          <Navbar.Brand className={styles.navbar}>
            <img
              alt="logo"
              src={logo}
              width="40"
              height="40"
              className={styles.logo}
            />
            A Glimpse of Jane
          </Navbar.Brand>
        </Container>
      </Navbar>
      <main>
        <Home />
        <BackToTop />
      </main>
    </>
  );
};

export default AppLayout;
