import { RefObject } from "react";

export { v4 as uuid } from "uuid";

export const getRandomInt = (min: number, max: number): number | undefined => {
  if (max < min) return undefined;
  return Math.floor(Math.random() * (max - min + 1));
};

export const openUrl = (url: string, target?: string) => {
  window.open(url, target);
};

export const scrollToView = (
  ref: RefObject<HTMLElement>,
  options: { [key: string]: string } = {}
) => {
  let scrollOption: { [key: string]: string } = {
    behavior: "smooth",
    block: "start"
  };
  for (let o in options) {
    scrollOption[o] = options[o];
  }
  if (ref && ref.current) {
    ref.current.scrollIntoView(scrollOption);
  }
};
