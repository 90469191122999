import { ComponentProps } from "react";
import { Stack } from "react-bootstrap";
import styles from "./work-experience.module.css";

interface ISkillsUsed extends ComponentProps<any> {
  skills: string[];
}
function SkillsUsed({ skills, className }: ISkillsUsed) {
  return (
    <Stack
      direction="horizontal"
      className={`d-flex flex-wrap ${className}`}
      gap={2}
    >
      {skills.map((s) => (
        <span key={s} className={styles.skill}>
          {s}
        </span>
      ))}
    </Stack>
  );
}

export default SkillsUsed;
