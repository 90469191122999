import { useCallback, useEffect, useState } from "react";
import { PiArrowLineUpBold } from "react-icons/pi";
import styles from "./back-to-top.module.css";
function BackToTop() {
  const [visible, setVisible] = useState(false);

  const toggleVisibility = useCallback(() => {
    const offset = 300;
    if (document.documentElement.scrollTop > offset) {
      setVisible(true);
    } else if (document.documentElement.scrollTop <= offset) {
      setVisible(false);
    }
  }, [setVisible]);

  const scrollToTop = useCallback(() => {
    document.documentElement.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, [toggleVisibility]);
  return (
    <PiArrowLineUpBold
      style={{ visibility: visible ? "visible" : "hidden" }}
      className={styles.container}
      onClick={scrollToTop}
    />
  );
}

export default BackToTop;
