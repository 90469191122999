import { EducationType } from "../../types";
import { Stack } from "react-bootstrap";
import styles from "./education.module.css";
function DegreeDetails({ education }: { education: EducationType }) {
  const { schoolName: school, from, to, degrees, majors, notes } = education;
  return (
    <Stack className={styles.infoContainer}>
      <div className={styles.school}>{school}</div>
      {degrees?.map((d) => (
        <div key={d}>
          {majors?.map((mj) => (
            <div className={styles.major} key={mj}>
              {mj}
            </div>
          ))}
          <div className={styles.notes}>{notes}</div>
          <div className={styles.degree}>{d}</div>
          <div className={styles.largeTime}>
            {from} - {to}
          </div>
        </div>
      ))}
    </Stack>
  );
}

export default DegreeDetails;
