import { Container } from "react-bootstrap";
import DegreeDetails from "./DegreeDetails";
import { educationHistory } from "./educationHistory";
import styles from "./education.module.css";
import { forwardRef } from "react";

export const Education = forwardRef<HTMLElement>((props, ref) => {
  return (
    <section className={`${styles.container} py-5`} ref={ref}>
      <Container className={styles.content}>
        {educationHistory.map((edu) => (
          <DegreeDetails
            education={edu}
            key={`${edu.schoolName}-${edu.from}`}
          />
        ))}
      </Container>
    </section>
  );
});
