import { useState, useEffect } from "react";

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
};
export const useWindowDimensions = () => {
  const [dimensions, setDimensions] = useState(getWindowDimensions());
  useEffect(() => {
    function handleResize() {
      setDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return dimensions;
};
