import { skillLevels } from "./skills";
import {
  type ChartData,
  type ChartOptions,
  Chart as ChartJS,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
  TooltipPositionerFunction,
  ChartType
} from "chart.js";
import { Pie } from "react-chartjs-2";
import { niceColors } from "../../constants/colors";
import { getRandomInt } from "../../utils/helpers";

ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);

declare module "chart.js" {
  interface TooltipPositionerMap {
    myCustomPositioner: TooltipPositionerFunction<ChartType>;
  }
}

Tooltip.positioners.myCustomPositioner = function (elements, eventPosition) {
  return {
    x: eventPosition.x - 3,
    y: eventPosition.y,
    xAlign: "right"
  };
};

const SkillChart = () => {
  const labels: string[] = [];
  const data: number[] = [];
  const backgroundColor: string[] = [];
  // const borderColor: string[] = [];
  skillLevels.forEach(({ name, years }, ind) => {
    labels.push(name);
    data.push(years);
    const bcString =
      ind < niceColors.rgb.length
        ? niceColors.rgb[ind]
        : `${getRandomInt(0, 255)},${getRandomInt(0, 255)},${getRandomInt(
            0,
            255
          )}`;
    backgroundColor.push(`rgba(${bcString},0.6)`);
    // borderColor.push(`rgba(${bcString},1)`);
  });
  const chartData: ChartData<"pie"> = {
    labels,
    datasets: [
      {
        label: "skills",
        data,
        backgroundColor,
        // borderColor,
        borderWidth: 1
      }
    ]
  };

  const options: ChartOptions<"pie"> = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 3 / 2,
    plugins: {
      legend: {
        position: "right"
      },
      tooltip: {
        callbacks: {
          label: (c) => `${c.raw} years`
        },
        position: "myCustomPositioner"
      }
    }
  };

  return <Pie data={chartData} options={options} style={{ maxHeight: 400 }} />;
};

export default SkillChart;
