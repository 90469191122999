import { Reference } from "../../types";

export const references: Reference[] = [
  {
    referrerName: "Lucas Pan, CTO, AXIA",
    relationShip: `Lucas Pan was the CTO of Fintelics. I reported to him directly during the 3.5 years when I worked at Fintelics.`,
    referenceLetter:
      "Jane is an excellent tech lead with a deep understanding of multiple software technologies, including frontend, backend, blockchain, and cloud. She is able to lead a team effectively and consistently delivers high-quality projects on time.",
    imgUrl:
      "https://images.unsplash.com/photo-1504805572947-34fad45aed93?auto=format&fit=crop&q=80&w=2070&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
  },
  {
    referrerName: "Paul Ungerman, Co-Founder, AXIA",
    relationShip:
      "Paul is the Co-Founder of AXIA. I worked closely with him during the 3.5 years while I was working for AXIA's projects",
    referenceLetter:
      "Jane Zhang started with the core development team of AXIA as a junior developer back in 2020. Over the span of three years, Jane went from a competent programmer to a senior team leader. It was due in no small measure to her incredible work ethic, her love of development and building integrated solutions that put the end user first. She may be quiet, but beneath that exterior lies a fierce competitor and loyal team member! Jane is a diligent, thoughtful and overall incredible person and equally talented developer who would be a fantastic addition to any team environment.",
    imgUrl:
      "https://images.unsplash.com/photo-1504805572947-34fad45aed93?auto=format&fit=crop&q=80&w=2070&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
  },
  {
    referrerName: "Will Zhang, CEO, Fintelics",
    relationShip:
      "Will is the CEO of Fintelics and he directly managed me while I was working at Fintelics",
    referenceLetter:
      "It was a great pleasure working with Jane, throughout the years at Fintelics, she has demonstrated tremendous amount of professionalism, her ability to learn, lead, and adapt to new challenges is truly astonishing. I wish her nothing but best for her future career advancement.",
    imgUrl:
      "https://images.unsplash.com/photo-1504805572947-34fad45aed93?auto=format&fit=crop&q=80&w=2070&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
  }
];
