import styles from "./footer.module.css";

function BilboBagginsQuote() {
  return (
    <div className={styles.quote}>
      <div>
        "For still there are so many things that I have never seen: in every
        wood in every spring there is a different green. I sit beside the fire
        and think of people long ago, and people who will see a world that I
        shall never know. But all the while I sit and think of times there were
        before, I listen for returning feet and voices at the door."
      </div>
      <div style={{ fontSize: 13, textAlign: "right" }}>
        <span className="fst-normal"> -- J.R.R. Tolkien.</span>
        <span className="fst-italic px-1"> The Lord of the Rings</span>
      </div>
    </div>
  );
}

export default BilboBagginsQuote;
