import { ReactNode } from "react";
import styles from "./home-anchor.module.css";
import { OverlayTrigger, Stack, Tooltip } from "react-bootstrap";

// in md and larger screens shown on the side
// in smaller screen shown as one round button that can be expended

interface IHomeAnchor {
  id: string;
  tooltip: string;
  icon: ReactNode;
}
export function HomeAnchor({ data }: { data: IHomeAnchor[] }) {
  return (
    <Stack className={`${styles.homeAnchor} d-none d-md-flex`} gap={3}>
      {data.map(({ id, tooltip, icon }) => (
        <OverlayTrigger
          overlay={
            <Tooltip id={`${id}-tooltip`} className={styles.tooltip}>
              {tooltip}
            </Tooltip>
          }
          placement="left"
          key={id}
        >
          <span className={styles.icon}>{icon}</span>
        </OverlayTrigger>
      ))}
    </Stack>
  );
}
