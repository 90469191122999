import BilboBagginsQuote from "./BilboBagginsQuote";
import styles from "./footer.module.css";
import { Stack } from "react-bootstrap";
import { AiOutlineLinkedin, AiOutlineGithub } from "react-icons/ai";
import { openUrl } from "../../utils";
import { forwardRef } from "react";

export const Footer = forwardRef<HTMLElement>((props, ref) => {
  const linkedInURL =
    "https://www.linkedin.com/in/zhenying-jane-zhang-912959112/";
  const githubURL = "https://github.com/GoodLuckJane";

  return (
    <footer ref={ref}>
      <div
        style={{ position: "relative", zIndex: 4 }}
        className={styles.content}
      >
        <div className={`${styles.author} py-4`}>
          Jane Zhang &#8226; Toronto
        </div>
        <Stack direction="horizontal" gap={3} className={styles.contact}>
          <AiOutlineLinkedin onClick={() => openUrl(linkedInURL, "_blank")} />
          <AiOutlineGithub onClick={() => openUrl(githubURL, "_blank")} />
          {/* <AiOutlineMail onClick={() => openUrl(emailURL)} /> */}
        </Stack>
        <BilboBagginsQuote />
      </div>
      <div className={`${styles.wave} d-none d-md-block`}></div>
    </footer>
  );
});
