import "bootstrap/dist/css/bootstrap.min.css";
import AppLayout from "./layout";
// TODO:
// 1. Error Boundary
// 2. Theme (color, dark/light)
function App() {
  return <AppLayout />;
}

export default App;
