import { SkillLevel } from "../../types";

export const skillLevels: SkillLevel[] = [
  {
    name: "JavaScript",
    years: 4.2
  },
  {
    name: "React Native",
    years: 4.2,
    yearsSinceLastUsed: 0.5
  },
  {
    name: "React",
    years: 3.5,
    yearsSinceLastUsed: 0
  },

  {
    name: "AWS",
    years: 3.2
  },
  {
    name: "ExpressJS",
    years: 3,
    yearsSinceLastUsed: 1
  },
  {
    name: "R",
    years: 3,
    yearsSinceLastUsed: 7
  },
  {
    name: "MATLAB",
    years: 1,
    yearsSinceLastUsed: 6
  },

  {
    name: "Java",
    years: 1
  },

  {
    name: "Vue",
    years: 0.25
  }
];
