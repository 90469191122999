import { ComponentProps } from "react";
import CountryFlag from "react-country-flag";

interface ILocationWithCountryFlat extends ComponentProps<any> {
  location: string;
}
function LocationWithCountryFlag({
  location,
  className
}: ILocationWithCountryFlat) {
  const locArr = location.split(",");
  const countryCode = locArr.pop()?.trim() || "";
  const locationWithoutCountry = locArr.join(",");
  return (
    <div className={`d-flex align-items-center ${className}`}>
      {locationWithoutCountry}
      <CountryFlag
        countryCode={countryCode}
        svg
        style={{ width: "2em", marginLeft: 5 }}
      />
    </div>
  );
}

export default LocationWithCountryFlag;
