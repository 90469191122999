import styles from "./summary.module.css";
import { Stack } from "react-bootstrap";
import LocationWithCountryFlag from "../LocationWithCountryFlag/LocationWithCountryFlag";
const UserSummary = () => {
  const user = {
    name: "Jane (Zhenying) Zhang",
    summary: "Full Stack Software Engineer - Frontend Focused",
    description: "4 years of working experience as a SWE",
    location: "Toronto, ON, CA"
  };
  return (
    <Stack
      gap={4}
      className={`align-items-start text-start ${styles.userSummaryContainer}`}
    >
      <div className={styles.name}>{user.name}</div>
      <LocationWithCountryFlag location={user.location} />
      <div className={styles.description}>{user.description}</div>
      <div className={styles.summary}>{user.summary}</div>
    </Stack>
  );
};

export default UserSummary;
